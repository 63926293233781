<template lang="pug">
  div.main
    GamesList(:showFilters="false" page="Main")
    LastNewsSW
    LeaderboardBlock
</template>

<script>
import {mapMutations} from 'vuex';

const GamesList = () => import('@/components/games/GamesListSW');
const LeaderboardBlock = () => import('@/components/leaderboard/LeaderboardBlockSW');
const LastNewsSW = () => import('@/components/lastnews/LastNewsSW');

export default {
  name: 'Main',
  components: {
    GamesList,
    LeaderboardBlock,
    LastNewsSW
  },
  methods: {
    ...mapMutations({
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
    })
  },
  mounted() {
    this.SET_OPEN_PAGE('root');
  }
};
</script>
<style lang="scss">
  .main {
    width: 100%;
  }
</style>